<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form sendsms Wizard-->
      <form class="form" @submit.prevent="getNewSecret">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <div class="col-lg-9 col-xl-9 d-flex align-items-center">
                  <div class="radio-inline">
                    <label class="radio" for="no">
                      <input
                        type="radio"
                        id="no"
                        name="is_responsible"
                        :value="1"
                        v-model="newSecretForm.otp_type"
                      />
                      <span></span>{{ $t('commons.mobile_phone') }}
                    </label>
                    <label class="radio" for="yes">
                      <input
                        type="radio"
                        id="yes"
                        name="is_responsible"
                        :value="2"
                        v-model="newSecretForm.otp_type"
                      />
                      <span></span>{{ $t('security.authenticator') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row flex-column" v-if="+newSecretForm.otp_type === 2">
                <template v-if="securitySettings.QR_Image">
                  <span class="col-12">{{ $t('security.authenticator_desc') }}</span>
                  <img :src="securitySettings.QR_Image" height="250" width="250" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-start border-top mt-5 pt-10">
          <button
            type="submit"
            class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
          >
            {{ $t('commons.submit') }}
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form sendsms Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SecuritySettingsComponent",
  data() {
    return {
      newSecretForm: {
        otp_type: 1,
        secret: ""
      }
      
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.profile.isLoading,
      securitySettings: state => state.profile.security_settings
    }),
    
  },
  methods: {
    ...mapActions("profile", [
      "GET_SECURITY_SETTINGS",
      "POST_SECURITY_SETTINGS"
    ]),
    getNewSecret() {
      const formData = { ...this.newSecretForm, secret: this.securitySettings?.secret };

      this.POST_SECURITY_SETTINGS({formData})
    }
    
  },
  created() {
    this.GET_SECURITY_SETTINGS();
  },
};
</script>